<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex">
        <span class="d-flex justify-center align-center">
          <img
            src="@/assets/icons/liveness-demo.svg"
            alt="age-icon"
            width="22"
            height="22"
          />
        </span>
        <div class="ml-2">
          <span class="main-heading">Liveness Detection</span>
          <v-icon
            v-if="!infoText"
            @click="infoText = true"
            class="info-icon cursor-pointer ml-2"
            >mdi-information-outline</v-icon
          >
        </div>
      </v-col>
      <v-col cols="12" class="" v-if="infoText">
        <v-alert color="age-alert" class="alert-card">
          <template v-slot:append>
            <img
              src="@/assets/icons/close-icon.svg"
              @click="infoText = false"
              class="cursor-pointer"
              height="22"
              width="22"
              alt="close-icon"
            />
          </template>
          <span class="info-text-card">
            Ensure the authenticity of user interactions with our Face Liveness
            Verification Service. This advanced feature uses real-time facial
            recognition technology to determine if an interaction is being made
            by a live person, rather than a photo, video, or other spoofing
            methods.
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <div v-if="!startLiveness" class="d-flex justify-center mt-5">
      <div class="loader-div" v-if="loader">
        <v-progress-circular
          :size="50"
          :width="3"
          color="#292b30"
          indeterminate
        ></v-progress-circular>
        <p class="generate-url">Generating Liveness URL</p>
      </div>
      <v-card v-if="verificationURL" class="generate-liveness-card">
        <div class="pa-5 top-heading-div">
          <span class="top-heading">Guidelines:</span>
        </div>
        <div class="sample-image-section">
          <div class="sample-image-text">
            <img src="../../assets/si1.png" height="190" width="144" />
            <span class="sample-text">Remove any masks</span>
          </div>
          <div class="sample-image-text">
            <img src="../../assets/si2.png" height="190" width="144" />
            <span class="sample-text">Remove glasses and hats</span>
          </div>
          <div class="sample-image-text">
            <img src="../../assets/si3.png" height="190" width="144" />
            <span class="sample-text">Ensure even lighting</span>
          </div>
          <div class="sample-image-text">
            <img src="../../assets/si4.png" height="190" width="144" />
            <span class="sample-text">Perfect Selfie</span>
          </div>
        </div>
        <!-- <div class="divider-line">
          <v-divider></v-divider>
        </div> -->
        <!-- <div class="liveness-url">
          <span>Liveness URL: </span>
          <div class="url-field ml-5">
            <span
              style="
                color: #4040f1;
                text-decoration: underline;
                font-weight: 400;
              "
              >{{ verificationURL }}</span
            >
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="copy-btn ml-3"
                icon
                @click="copyToClipboard(verificationURL)"
                ><v-icon style="font-size: 16px"
                  >mdi-content-copy</v-icon
                ></v-btn
              >
            </template>
            {{ copyMessage }}
          </v-tooltip>
        </div> -->
        <div class="action-section">
          <div class="facia-logo">
            <span>Powered by:</span>
            <img src="../../assets/logo/facia.png" height="16" />
          </div>
          <div class="mt-5 mb-1">
            <v-btn
              elevation="0"
              class="copy-url-btn"
              :loading="copyLoading"
              @click="copyToClipboard(verificationURL)"
            >
              Copy Liveness URL
              <v-icon class="ml-2" size="12" color="#292B30"
                >mdi-content-copy</v-icon
              >
            </v-btn>
            <v-btn
              elevation="0"
              :loading="iframeURL"
              class="liveness-btn"
              @click="(startLiveness = true), (iframeURL = true)"
              >Start Liveness
              <v-icon size="18">mdi-chevron-right</v-icon></v-btn
            >
          </div>
        </div>
      </v-card>
    </div>
    <v-row
      v-else
      color="appBackGround"
      elevation="0"
      justify="center"
      align-content="start"
    >
      <div class="text-center">
        <v-skeleton-loader
          v-if="iframeURL"
          class="mx-auto card-loader"
          type="card"
        ></v-skeleton-loader>
        <iframe
          :src="verificationURL"
          allow="camera;microphone"
          frameborder="0"
          :width="$vuetify.breakpoint.mdAndDown ? '350px' : '1264px'"
          height="650px"
          ref="iframe"
          loading="lazy"
          @load="iframeURL = false"
        ></iframe>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { showSimpleErrorMessage } from "@/utils/showError";
import ApiService from "../../services/Api";
import { showErrorMessage, showNotificationBar } from "@/utils/showError";
export default {
  data() {
    return {
      phase: "showdemo",
      verificationURL: null,
      clientSecret: null,
      clientId: null,
      infoText: true,
      loader: false,
      startLiveness: false,
      copyMessage: "Click to copy",
      iframeURL: false,
      copyLoading: false,
    };
  },
  mounted() {
    this.checkCanCallDemo();
  },
  methods: {
    reloadPage() {
      location.reload();
    },
    /**
     * Calculates the width of the skeleton loader based on the Vuetify breakpoints.
     * @returns {string} The width of the skeleton loader.
     */
    getSkeletonLoaderWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%"; // Adjust the width accordingly
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "350px"; // Adjust the width accordingly
      } else {
        return "750px"; // Adjust the width accordingly
      }
    },

    /**
     * Adds a message listener to handle messages from the iframe.
     */
    addMessageListener() {
      window.onmessage = (e) => {
        if (e.data === "show-result") {
          this.phase = "showresults";
        }
      };
    },

    /**
     * Checks if it's possible to call the demo by getting the client credentials and generating the liveness URL.
     */
    async checkCanCallDemo() {
      this.loader = true;
      await ApiService.GetRequest("/merchant/client")
        .then((res) => {
          this.clientSecret = res.data.result.data.client_secret;
          this.clientId = res.data.result.data.client_id;
          ApiService.PostRequest(
            "/transaction/generate-liveness-url",
            null,
            "iframe",
            this.clientSecret
          )
            .then((response) => {
              if (response.status === 200) {
                this.verificationURL = response.data.result.data.liveness_url;
                this.loader = false;
                if (this.verificationURL !== null) {
                  this.phase = "showiframe";
                }
                if (this.clientId && this.clientSecret) {
                  this.addMessageListener();
                } else {
                  showSimpleErrorMessage(
                    "please login again client secret or client id is not available!"
                  );
                }
              }
            })
            .catch((error) => {
              this.loader = false;
              showErrorMessage(error);
              if (error.response.status === 401) {
                this.$store.dispatch("logout");
                this.$store.dispatch("SET_LOGOUT");
                this.$router.push("/");
              }
            });
        })
        .catch((err) => {
          this.loader = false;
          showErrorMessage(err);
        });
    },
    /**
     * @description Copies the provided string to the clipboard
     * @param {string} key - The string to be copied
     */
    copyToClipboard(key) {
      navigator.clipboard.writeText(key);
      this.copyMessage = "Copied!";
      this.copyLoading = true;
      setTimeout(() => {
        this.copyMessage = "Click to copy";
        this.copyLoading = false;
        showNotificationBar("success", "Liveness URL Copied.");
      }, 500);
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 2rem;
}

.age-alert {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background: #fafafa;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.age-alert /deep/ .v-alert__wrapper {
  align-items: start;
}

.age-alert /deep/ .v-alert__content {
  text-align: justify;
  padding-right: 40px;
}
.info-text-card {
  width: 50rem;
  color: #000;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.generate-liveness-card {
  width: 700px;
  border: 1px solid #fff;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.card-loader {
  min-height: 450px;
  width: 700px;
}
.main-heading {
  color: #292b30;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  font-size: 20px;
}
.top-heading-div {
  width: 100%;
  text-align: left;
}
.top-heading {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}
.sample-image-section {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.sample-image-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
}
.sample-text {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0px;
}

.loader-div {
  height: 250px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.generate-url {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 7rem;
  font-size: 14px;
}
.liveness-url {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 15px;
  width: 665px;
}
.url-field {
  height: 40px;
  width: 465px;
  background: #fafafa;
  border: 1px solid #e8ebf6;
  padding: 10px;
  border-radius: 6px;
  overflow: scroll;
  white-space: nowrap;
}
.copy-btn {
  font-size: 16px;
  color: #213cc8;
  height: 40px;
  width: 40px;
  background: #fafafa;
  border: 1px solid #e8ebf6;
  padding: 10px;
  border-radius: 6px;
}
.facia-logo {
  color: #292b30;
  font-family: Inter;
  font-style: normal;
  font-size: 10px;
  display: flex;
  padding: 0 16px;
}
.action-section {
  padding: 0px 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.divider-line {
  width: 665px;
  padding: 10px;
}
.copy-url-btn {
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  background: #fff !important;
  height: 32px;
  padding: 10px;
  color: #292b30 !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px;
  text-transform: capitalize;
}
.liveness-btn {
  border-radius: 8px;
  background: #213cc8 !important;
  height: 32px;
  padding: 10px;
  color: #fff !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px;
  text-transform: capitalize;
}

.alert-card {
  border-radius: 0.375rem;
  border: 1px solid #e3e3e3;
  background: #fafafa;
}

.info-icon {
  font-size: 16px;
  margin-top: -4px;
  /* Adjust the margin-top to align the icon properly */
}

.v-skeleton-loader__card.v-skeleton-loader__bone {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .sample-image-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .liveness-url {
    flex-direction: column;
    align-items: center;
  }
  .url-field {
    width: 50%;
    overflow: scroll;
    white-space: nowrap;
  }
  .action-section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .divider-line {
    width: 100%;
  }
}
</style>
